<template>
  <div>
    <div class="tab">
      <div :class="active == 0 ? 'item active' : 'item'">查看详情</div>
    </div>
    <div class="list">
      <div class="item">
        <div class="quiz">
          <div class="quiz_1">提问:</div>
          <div class="quiz_2">
            {{ item.question }}
          </div>
        </div>
        <div class="quiztime">
          &nbsp;&nbsp;提问时间：<span>{{ item.time }}</span>
        </div>
        <div class="answer">
          <div class="answer_1">回答:</div>
          <div class="answer_2">
            {{ item.answer ? item.answer : "暂无回答!" }}
          </div>
        </div>
        <div class="quiztime">
          &nbsp;&nbsp;回答时间：<span>{{
            item.answer_time ? item.answer_time : ""
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      active: 0,
      item: {},
    };
  },
  computed: {
    ...mapState({
      classid: (state) => state.classid,
      userInfo: (state) => state.userInfo,
    }),
  },
  methods: {
    ...mapActions(["getMineQuestionDetail"]),
  },
  mounted() {
    this.getMineQuestionDetail({
      username: this.userInfo.username,
      id: this.$route.query.id,
    }).then((res) => {
      if (res.code == 200) {
        this.item = res.data;
      }
    });
  },
};
</script>

<style lang='scss' scoped>
.tab {
  display: flex;
  height: 77px;
  line-height: 77px;
  justify-content: space-around;
  border-bottom: 1px solid #e1e1e1;
  .item {
    cursor: pointer;
    position: relative;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #a7a7a7;
  }
  .active {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
    &::before {
      position: absolute;
      display: inline-block;
      content: "";
      width: 56px;
      height: 4px;
      background: #137cfb;
      bottom: -3px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
.list {
  .item {
    margin-top: 45px;
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 15px;
    .quiz {
      display: flex;
      line-height: 18px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      .quiz_1 {
        text-align: center;
        line-height: 20px;
        width: 42px;
        height: 20px;
        background: #137cfb;
        border-radius: 1px;
        color: #fff;
      }
      .quiz_2 {
        color: #7d7d7d;
        margin-left: 5px;
      }
    }
    .quiztime {
      margin-top: 10px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #7d7d7d;
      span {
        color: #cacaca;
      }
    }
    .answer {
      margin-top: 10px;
      display: flex;
      line-height: 16px;
      font-size: 14px;
      .answer_1 {
        text-align: center;
        width: 42px;
        height: 20px;
        background: #d9eaff;
        border-radius: 1px;
        color: #137cfb;
        line-height: 20px;

        font-size: 14px;
      }
      .answer_2 {
        margin-left: 5px;
        color: #7d7d7d;
      }
    }
  }
}
</style>